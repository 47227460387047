export default {
  'roles.title': 'Roles management',
  'roles.roleName': 'Roles name',
  'roles.createDateTime': 'Create at',
  'roles.internalComment': 'Description',
  'roles.list': 'Roles list',
  'roles.action': 'Action',
  'roles.deleteContent': 'Do you want to delete this roles?',
  'roles.permissions': 'Permissions',
  'roles.create': 'Create roles',
  'roles.update': 'Update roles',
  'roles.validatorRoleName': 'Roles name is not valid',
  'roles.information': 'Roles information',

  roles: 'Roles',
  RolesModule: 'Roles',
  'RolesModule.create': 'Create roles',
  'RolesModule.delete': 'Delete roles',
  'RolesModule.update': 'Update roles',
  'RolesModule.view': 'View detail roles',

  users: 'User',
  UsersModule: 'User',
  'UsersModule.create': 'Create user',
  'UsersModule.delete': 'Delete user',
  'UsersModule.update': 'Update user',
  'UsersModule.view': 'View detail user',

  services: 'Services',
  ServicesModule: 'Services',
  'ServicesModule.create': 'Create services',
  'ServicesModule.delete': 'Delete services',
  'ServicesModule.update': 'Update services',
  'ServicesModule.view': 'View detail services',

  devices: 'Devices',
  DevicesModule: 'Devices',
  'DevicesModule.create': 'Create devices',
  'DevicesModule.delete': 'Delete devices',
  'DevicesModule.update': 'Update devices',
  'DevicesModule.view': 'View detail devices',

  deviceTypes: 'Device types',
  DeviceTypesModule: 'Device types',
  'DeviceTypesModule.create': 'Create device types',
  'DeviceTypesModule.delete': 'Delete device types',
  'DeviceTypesModule.update': 'Update device types',
  'DeviceTypesModule.view': 'View detail device types',

  ticket: 'Ticket',
  TicketModule: 'Ticket',
  'TicketModule.create': 'Create ticket',
  'TicketModule.delete': 'Delete ticket',
  'TicketModule.update': 'Update ticket',
  'TicketModule.view': 'View detail ticket',

  serviceDesk: 'Service desks',
  ServiceDeskModule: 'Service desks',
  'ServiceDeskModule.create': 'Create service desks',
  'ServiceDeskModule.delete': 'Delete service desks',
  'ServiceDeskModule.update': 'Update service desks',
  'ServiceDeskModule.view': 'View detail service desks',
  'ServiceDeskModule.controlTicket': 'Management ticket',

  Rating: 'Rating',
  'Rating.view': 'View rating',
  Report: 'Report',
  'Report.view': 'View report',
};
