export default {
  'serviceDesk.name': 'Ticket management',
  'serviceDesk.heading': 'Please select service:',

  'informationServicesDetail.totalTicket': 'Ordinal number issue',
  'informationServicesDetail.totalWaiting': 'Ordinal number waiting',
  'informationServicesDetail.totalCompleted': 'Ordinal number used',
  'informationServicesDetail.totalCancel': 'Ordinal number skipped',
  'informationServicesDetail.ticketIndex': 'Ordinal number',
  'informationServicesDetail.ticketStatus': 'Status',
  'informationServicesDetail.createDateTime': 'Time issue',
  'informationServicesDetail.serviceDesk.serviceDeskName': 'Service Desk',
  'informationServicesDetail.priority': 'Priority',
  'informationServicesDetail.wait': 'Waiting',
  'informationServicesDetail.process': 'Processing',
  'informationServicesDetail.done': 'Completed',
  'informationServicesDetail.cancel': 'Absent',
  'informationServicesDetail.list': "Ordinal number list of service's",
  'informationServicesDetail.stt': 'Ordinal number',
  'informationServicesDetail.service': 'Service',
  'informationServicesDetail.createTime': 'Time issue',
  'informationServicesDetail.exDateTime': 'Expiry time',

  'informationServicesDetail.priority-title': 'Priority',
  'informationServicesDetail.priority-content': 'Do you want to prioritize this ordinal number?',
  'informationServicesDetail.call': 'Call',
  'informationServicesDetail.recall': 'Recall',
  'informationServicesDetail.call-next': 'Next number',
  'informationServicesDetail.finish': 'Completed',

  'informationServicesDetail.cancel-title': 'Call the next ordinal number',
  'informationServicesDetail.done-title': 'Completed',
  'informationServicesDetail.cancel-content':
    'Do you want to skip this ordinal number and call the next ordinal number?',
  'informationServicesDetail.done-content': 'Do you want confirm completed?',
  'informationServicesDetail.empty':
    'All of ordinal number are out of stock. Please wait until have new ordinal number!',

  'informationServicesDetail.close': 'Close',
  'informationServicesDetail.close-title': 'Close',
  'informationServicesDetail.close-content': 'Do you want to close this service desk?',
  'informationServicesDetail.open': 'Open',
  'informationServicesDetail.closeServiceDesk': 'Closing',
  'informationServicesDetail.serviceNA': 'Service is not available',
};
