export default {
  'evaluate.name': 'Đánh giá',
  'evaluate.title': 'Quản lý đánh giá của khách hàng',
  'evaluate.information': 'Thông tin đánh giá',

  'evaluate.ticketIndex': 'STT đánh giá',
  'evaluate.service.serviceName': 'Tên dịch vụ sử dụng',
  'evaluate.serviceDesk.serviceDeskName': 'Quầy dịch vụ sử dụng',
  'evaluate.ticketRating': 'Đánh giá',
  'evaluate.internalComment': 'Nội dung',
  'evaluate.ratingDateTime': 'Thời gian đánh giá',
  'evaluate.action': 'Hành động',

  'evaluate.serviceName': 'Dịch vụ sử dụng',
  'evaluate.serviceDeskName': 'Quầy dịch vụ',
  'evaluate.processBy': 'Người thực hiện',
  'evaluate.createDateTime': 'Thời gian thực hiện',

  'evaluate.createdAt': 'Chọn thời gian',
  'evaluate.table.service': 'Dịch vụ',
  'evaluate.table.serviceDesk': 'Quầy dịch vụ',

  'evaluate.filter.ticketRating': 'Đánh giá',
  'evaluate.ticketRating.one': '1 sao',
  'evaluate.ticketRating.two': '2 sao',
  'evaluate.ticketRating.three': '3 sao',
  'evaluate.ticketRating.four': '4 sao',
  'evaluate.ticketRating.five': '5 sao',
};
