import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { IRouter } from '@routers/interface';

const routerDeskView: IRouter = {
  path: '/desk/:code/ticket',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'view.name', //translate here for breadcrumb and sidebar
  masterLayout: false,
};

export default routerDeskView;