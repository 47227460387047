export default {
  'services.name': 'Services',
  'services.servicesManagement': 'Services management',
  'services.serviceCode': 'Services code',
  'services.serviceName': 'Services name',
  'services.desks': 'Services desks',
  'services.createDateTime': 'Create time',
  'services.isActive': 'Status',
  'services.isActive.id': '{status, select, 1 {Online} 2 {Offline} other {Undefined}}',
  'services.action': 'Actions',
  'services.dateFrom': 'From',
  'services.dateTo': 'To',
  'services.internalComment': 'Description',
  'services.create': 'Create services',
  'services.update': 'Update services',
  'services.information': 'Services information',
  'services.addTime': 'Add time',
  'services.time': 'Time apply',
  'services.requiredTime': 'Please select time',
  'services.applyBy': 'Apply by',
  'services.dayOfWeek': 'Day',
  'services.dateOfMonth': 'Date',
  'services.monday': 'Monday',
  'services.tuesday': 'Tuesday',
  'services.wednesday': 'Wednesday',
  'services.thursday': 'Thursday',
  'services.friday': 'Friday',
  'services.saturday': 'Saturday',
  'services.sunday': 'Sunday',
  'services.requiredDay': 'Please select day',
  'services.serviceThumbnail': 'Image',
  'services.deleteContent': 'Do you want to delete this services?',
  'services.informationPage': 'Services detail',
  'informationServices.ticketIndex': 'No.',
  'informationServices.ticketStatus': 'Status',
  'informationServices.ticketStatus.id':
    '{status, select, 0 {Waiting} 1 {Processing} 2 {Complete} 3 {Absent} other {Undefined}}',
  'informationServices.endDateTime': 'Time used',
  'informationServices.processBy.userFullName': 'Process by',
  'informationServices.serviceDesk.serviceDeskName': 'Services desks',
  'services.activeTimes': 'Times active',
  'services.times': 'Time',
  'services.dayApply': 'Day apply',
  'services.deskServices': 'Services desk',
  'services.ticketStatus.waiting': 'Waiting',
  'services.ticketStatus.process': 'Processing',
  'services.ticketStatus.done': 'Complete',
  'services.ticketStatus.cancel': 'Absent',
  'services.ticketStatus': 'Status',
  'services.validatorServiceCode': 'Services must have 2 characters and no space',
  'services.validatorServiceName': 'Services name is not valid',

  'services.giveNumber': 'Get ticket',
  'services.giveNumberTitle': 'No ticket',
  'services.at': 'At',
  'services.createTime': 'Create at',
  'services.endTime': 'End time',
  'services.print': 'Print',

  'services.error-title': 'Can not get ticket',
  'services.error-text':
    'Can not get ticket because this service is not available. Please check again !',

  'services.intendTime': 'Intend time',
};
