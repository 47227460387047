export const logo = require('./logo.png');
export const imgAvatar = require('./avatar.jpg');
export const languageVN = require('./vn.png');
export const languageEN = require('./en.png');
export const Img404 = require('./error.svg');
export const iconTotal = require('./iconTotal.png');
export const iconDevice = require('./iconDevice.png');
export const iconHeineken = require('./iconHeineken.png');
export const iconOther = require('./iconOther.png');
export const iconDot = require('./dotIcon.png');
export const exportFile = require('./ExportFile.png');
export const restart = require('./Restart.png');

export const totalCancel = require('./totalCancel.png');
export const totalCompleted = require('./totalCompleted.png');
export const totalTicket = require('./totalTicket.png');
export const totalWaiting = require('./totalWaiting.png');

export { default as ArrowLeftImg } from './arrow-left.png';
export { default as LogoutIcon } from './LogoutIcon.png';
