export default {
  'setting.name': 'Cài đặt hệ thống',
  'deviceType.name': 'Loại thiết bị',
  'deviceType.title': 'Loại thiết bị',
  'deviceType.typeName': 'Loại thiết bị',
  'deviceType.typeCode': 'Mã thiết bị',
  'deviceType.internalComment': 'Mô tả',
  'deviceType.action': 'Hành động',

  'deviceType.create': 'Thêm loại thiết bị',
  'deviceType.update': 'Cập nhật loại thiết bị',
  'deviceType.information': 'Thông tin thiết bị',
  'deviceType.isActive': 'Trạng thái',
  'deviceType.status.active': 'Đang hoạt động',
  'deviceType.status.notActive': 'Ngưng hoạt động',
  'deviceType.delete.title': 'Xóa',
  'deviceType.delete.content': 'Bạn có muốn xóa thiết bị này?',
  'deviceType.validator-typeName': 'Loại thiết bị không được để trống',
  'deviceType.validator-typeCode': 'Mã thiết bị không được để trống',

  'deviceType.placeholder.description': 'Vui lòng nhập mô tả',
  'deviceType.isActive.id': '{status, select, 1 {Đang hoạt động} 0 {Ngừng hoạt động} other {--} }',
};
