import React from 'react';

import { IRouter } from '@routers/interface';

const routerDetailServiceDesk: IRouter = {
  path: '/control-ticket/:serviceDeskId/:serviceId/:serviceName',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'serviceDeskDetail.name', //translate here for breadcrumb and sidebar
  masterLayout: false,
};

export default routerDetailServiceDesk;
