export default {
  'serviceDesk.name': 'Quản lý cấp số',
  'serviceDesk.heading': 'Vui lòng chọn quầy dịch vụ:',

  'informationServicesDetail.totalTicket': 'Số thứ tự đã cấp',
  'informationServicesDetail.totalWaiting': 'Số thứ tự đang chờ',
  'informationServicesDetail.totalCompleted': 'Số thứ tự đã hoàn thành',
  'informationServicesDetail.totalCancel': 'Số thứ tự vắng',
  'informationServicesDetail.ticketIndex': 'Số thứ tự',
  'informationServicesDetail.ticketStatus': 'Trạng thái',
  'informationServicesDetail.createDateTime': 'Thời gian cấp',
  'informationServicesDetail.serviceDesk.serviceDeskName': 'Quầy dịch vụ',
  'informationServicesDetail.priority': 'Ưu tiên',
  'informationServicesDetail.wait': 'Đang chờ',
  'informationServicesDetail.process': 'Đang thực hiện',
  'informationServicesDetail.done': 'Đã hoàn thành',
  'informationServicesDetail.cancel': 'Vắng',
  'informationServicesDetail.list': 'Danh sách số thứ tự của dịch vụ',
  'informationServicesDetail.stt': 'Số thứ tự đến lượt',
  'informationServicesDetail.service': 'Dịch vụ',
  'informationServicesDetail.createTime': 'Thời gian cấp',
  'informationServicesDetail.exDateTime': 'Hạn sử dụng',

  'informationServicesDetail.priority-title': 'Ưu tiên',
  'informationServicesDetail.priority-content': 'Bạn có muốn ưu tiên số thứ tự này',
  'informationServicesDetail.call': 'Gọi',
  'informationServicesDetail.recall': 'Gọi lại',
  'informationServicesDetail.call-next': 'Gọi số tiếp theo',
  'informationServicesDetail.finish': 'Hoàn thành',

  'informationServicesDetail.cancel-title': 'Gọi số tiếp theo',
  'informationServicesDetail.done-title': 'Hoàn thành',
  'informationServicesDetail.cancel-content':
    'Bạn có muốn bỏ qua số thứ tự này và gọi số tiếp theo không?',
  'informationServicesDetail.done-content': 'Xác nhận hoàn thành.',
  'informationServicesDetail.empty':
    'Đã hết số thứ tự chờ của dịch vụ. Vui lòng chờ đến khi có số thứ tự tiếp theo!',

  'informationServicesDetail.close': 'Đóng quầy',
  'informationServicesDetail.close-title': 'Đóng quầy',
  'informationServicesDetail.close-content': 'Bạn có muốn đóng quầy này?',
  'informationServicesDetail.open': 'Mở quầy',
  'informationServicesDetail.closeServiceDesk': 'Quầy đang đóng',
  'informationServicesDetail.serviceNA': 'Dịch vụ đang ngưng hoạt động',
};
