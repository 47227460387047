export default {
  'setting.name': 'System installation',
  'deviceType.name': 'Type of device',
  'deviceType.title': 'Type of device',
  'deviceType.typeName': 'Type of device',
  'deviceType.typeCode': 'Device code',
  'deviceType.internalComment': 'Description',
  'deviceType.action': 'Action',

  'deviceType.create': 'Add type of device',
  'deviceType.update': 'Update the type of device',
  'deviceType.information': 'Device information',
  'deviceType.isActive': 'Status',
  'deviceType.status.active': 'Active',
  'deviceType.status.notActive': 'Not active',
  'deviceType.delete.title': 'Delete',
  'deviceType.delete.content': 'Do you want to delete this device?',
  'deviceType.validator-typeName': 'The type of device is not blank',
  'deviceType.validator-typeCode': 'The device code is not blank',

  'deviceType.placeholder.description': 'Please enter the description',
  'deviceType.isActive.id': '{status, select, 1 {Active} 0 {Not Active} other {--} }',
};
