export default {
  'paymentChannel.name': 'Kênh thanh toán',
  'paymentChannel.title': 'Danh sách kênh thanh toán',
  'paymentChannel.illustration': 'Hình ảnh',
  'paymentChannel.code': 'Mã định danh',
  'paymentChannel.tagName': 'Tên kênh thanh toán',
  'paymentChannel.link': 'Đường dẫn truy cập',
  'paymentChannel.total': 'Tổng số đơn hàng',
  'paymentChannel.numberOfOrdersPaid': 'Số đơn đã thanh toán',
  'paymentChannel.numberOfChecked': 'Số đơn đã đối soát',
  'paymentChannel.action': 'Hành động',
  'paymentChannel.create': 'Thêm kênh thanh toán',
  'paymentChannel.update': 'Chỉnh sửa kênh thanh toán',
  'paymentChannel.information': 'Thông tin kênh thanh toán',
  'paymentChannel.securityCode': 'Mã bảo mật',
  'paymentChannel.Webhook': 'Webhook',
  'paymentChannel.image': 'Hình ảnh',
  'paymentChannel.content.delete': 'Bạn có chắc chắn muốn xóa kênh thanh toán này?',
};
