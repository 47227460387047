export default {
  'device.name': 'Device',
  'device.title': 'List of devices',
  'device.deviceCode': 'Device code',
  'device.deviceName': 'Device name',
  'device.deviceIP': 'IP address',
  'device.isActive': 'Operating status',
  'device.services': 'Usage service',
  'device.action': 'Act',

  'device.create': 'Add equipment',
  'device.information': 'Device Information',
  'device.update': 'Device update',

  'device.devicePassword': 'Password',
  'device.modal.form.confirmPassWord': 'Confirm password',
  'deviceType.isActive': 'Operating status',
  'device.type': 'Type of device',
  'device.status.active': 'Active',
  'device.status.notActive': 'Not active',
  'device.filter.status': 'Operating status',
  'device.modal.form.password.placeholder': 'Please enter a password',
  'device.name.placeholder': 'Please choose the type of device',
  'device.services.servicesName': 'Usage service',
  'device.validator-deviceCode': 'The device code is not blank',
  'device.validator-deviceName': "The device's name is not blank",
  'device.delete.content': 'Do you want to delete this device?',

  'device.isActive.id': '{status, select, 1 {Active} 0 {Not Active} other {--} }',
};
