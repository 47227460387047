import React from 'react';
import { Table } from 'react-feather';

import { IRouter } from '@routers/interface';
import { PermissionEnum } from '@shared/permissionCode/code';

const routerServiceDesk: IRouter = {
  path: '/control-ticket',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'serviceDesk.name', //translate here for breadcrumb and sidebar
  masterLayout: false,
  menu: {
    icon: <Table />,
  },
  permissionCode: [PermissionEnum.SERVICE_DESK_MODULE_CONTROL_TICKET],
};

export default routerServiceDesk;
