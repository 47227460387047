export default {
  'ticket.name': 'Ticket',
  'ticket.title': 'Ticket management',
  'ticket.ticketName': 'Service name',
  'ticket.createDateTime': 'Granting time',
  'ticket.endDateTime': 'Used Time',
  'ticket.ticketStatus': 'Status',
  'ticket.processBy.userFullName': 'Performer',
  'ticket.action': 'Action',
  'ticket.serviceDesk.serviceDeskName': 'Service counter',

  'ticket.information': 'Number of information',
  'ticket.ticketIndex': 'Numerical order',
  'ticket.service': 'service name',
  'ticket.serviceDesk': 'Service counter',

  'ticket.createdAt': 'Granting time',
  'ticket.filter.status': 'Operating status',
  'ticket.ticketStatus.wait': 'Waiting',
  'ticket.ticketStatus.done': 'Accomplished',
  'ticket.ticketStatus.cancel': 'Absent',
  'ticket.ticketStatus.process': 'Process',
  'ticket.table.service': 'service name',
  'ticket.startDay': 'Since',
  'ticket.endDay': 'To date',
  'ticket.processBy': 'Performer',

  'ticket.ticketStatus.id':
    '{status, select, 0 {Waiting} 1 {Process} 2 {Accomplished} other {Absent}}',
};
