import React from 'react';

import { IRouter } from '@routers/interface';

const routerInformationPage: IRouter = {
  path: '/service/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'services.informationPage', //translate here for breadcrumb and sidebar
  masterLayout: true,
};

export default routerInformationPage;
