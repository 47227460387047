import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { IRouter } from '@routers/interface';
import { PermissionEnum } from '@shared/permissionCode/code';

const routerUsers: IRouter = {
  path: '/setting/users',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'users.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  icon: <UserOutlined />,
  permissionCode: PermissionEnum.USER_MODULE,
};

export default routerUsers;
