import React from 'react';
import * as Icon from 'react-feather';

import { IRouter } from '@routers/interface';
import { PermissionEnum } from '@shared/permissionCode/code';

const routerDeviceType: IRouter = {
  path: '/setting/device-type',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'deviceType.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  icon: <Icon.Monitor />,
  permissionCode: PermissionEnum.DEVICE_TYPES_MODULE,
};

export default routerDeviceType;
