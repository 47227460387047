export default {
  'device.name': 'Thiết bị',
  'device.title': 'Danh sách thiết bị',
  'device.deviceCode': 'Mã thiết bị',
  'device.deviceName': 'Tên thiết bị',
  'device.deviceIP': 'Địa chỉ IP',
  'device.isActive': 'Trạng thái hoạt động',
  'device.services': 'Dịch vụ sử dụng',
  'device.action': 'Hành động',

  'device.create': 'Thêm thiết bị',
  'device.information': 'Thông tin thiết bị',
  'device.update': 'Cập nhật thiết bị',

  'device.devicePassword': 'Mật khẩu',
  'device.modal.form.confirmPassWord': 'Xác nhận mật khẩu',
  'deviceType.isActive': 'Trạng thái hoạt động',
  'device.type': 'Loại thiết bị',
  'device.status.active': 'Hoạt động',
  'device.status.notActive': 'Ngưng hoạt động',
  'device.filter.status': 'Trạng thái hoạt động',
  'device.modal.form.password.placeholder': 'Vui lòng nhập mật khẩu',
  'device.name.placeholder': 'Vui lòng chọn loại thiết bị',
  'device.services.servicesName': 'Dịch vụ sử dụng',
  'device.validator-deviceCode': 'Mã thiết bị không được để trống',
  'device.validator-deviceName': 'Tên thiết bị không được để trống',
  'device.delete.content': 'Bạn có muốn xóa thiết bị này?', 

  'device.isActive.id': '{status, select, 1 {Hoạt động} 0 {Ngưng hoạt động} other {--} }',
};
