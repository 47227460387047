export enum PermissionEnum {
  ROLES_MODULE_CREATE = 'RolesModule.create',
  ROLES_MODULE_DELETE = 'RolesModule.delete',
  ROLES_MODULE_UPDATE = 'RolesModule.update',
  ROLES_MODULE_VIEW = 'RolesModule.view',
  ROLES_MODULE = 'RolesModule',

  USER_MODULE_CREATE = 'UsersModule.create',
  USER_MODULE_DELETE = 'UsersModule.delete',
  USER_MODULE_UPDATE = 'UsersModule.update',
  USER_MODULE_VIEW = 'UsersModule.view',
  USER_MODULE = 'UsersModule',

  SERVICES_MODULE_CREATE = 'ServicesModule.create',
  SERVICES_MODULE_DELETE = 'ServicesModule.delete',
  SERVICES_MODULE_UPDATE = 'ServicesModule.update',
  SERVICES_MODULE_VIEW = 'ServicesModule.view',
  SERVICES_MODULE = 'ServicesModule',

  DEVICES_MODULE_CREATE = 'DevicesModule.create',
  DEVICES_MODULE_DELETE = 'DevicesModule.delete',
  DEVICES_MODULE_UPDATE = 'DevicesModule.update',
  DEVICES_MODULE_VIEW = 'DevicesModule.view',
  DEVICES_MODULE = 'DevicesModule',

  DEVICE_TYPES_MODULE_CREATE = 'DeviceTypesModule.create',
  DEVICE_TYPES_MODULE_DELETE = 'DeviceTypesModule.delete',
  DEVICE_TYPES_MODULE_UPDATE = 'DeviceTypesModule.update',
  DEVICE_TYPES_MODULE_VIEW = 'DeviceTypesModule.view',
  DEVICE_TYPES_MODULE = 'DeviceTypesModule',

  TICKET_MODULE_CREATE = 'TicketModule.create',
  TICKET_MODULE_DELETE = 'TicketModule.delete',
  TICKET_MODULE_UPDATE = 'TicketModule.update',
  TICKET_MODULE_VIEW = 'TicketModule.view',

  SERVICE_DESK_MODULE_CREATE = 'ServiceDeskModule.create',
  SERVICE_DESK_MODULE_DELETE = 'ServiceDeskModule.delete',
  SERVICE_DESK_MODULE_UPDATE = 'ServiceDeskModule.update',
  SERVICE_DESK_MODULE_VIEW = 'ServiceDeskModule.view',
  SERVICE_DESK_MODULE_CONTROL_TICKET = 'ServiceDeskModule.controlTicket',
  SERVICE_DESK_MODULE = 'ServiceDeskModule',

  RATING_VIEW = 'Rating.view',
  REPORT_VIEW = 'Rating.view',
  SETTING = 'Setting',
  GIVE_TICKET = 'Ticket',
}

export interface IPermissionValue {
  FE_KEY: PermissionEnum;
  BE_KEY: Array<PermissionEnum>;
  TYPE: 'AND' | 'OR';
}

export const custom: { data: Array<IPermissionValue> } = {
  data: [
    {
      FE_KEY: PermissionEnum.ROLES_MODULE,
      BE_KEY: [
        PermissionEnum.ROLES_MODULE_CREATE,
        PermissionEnum.ROLES_MODULE_VIEW,
        PermissionEnum.ROLES_MODULE_DELETE,
        PermissionEnum.ROLES_MODULE_UPDATE,
      ],
      TYPE: 'OR',
    },
    {
      FE_KEY: PermissionEnum.SETTING,
      BE_KEY: [
        PermissionEnum.ROLES_MODULE_CREATE,
        PermissionEnum.ROLES_MODULE_VIEW,
        PermissionEnum.ROLES_MODULE_DELETE,
        PermissionEnum.ROLES_MODULE_UPDATE,

        PermissionEnum.DEVICE_TYPES_MODULE_CREATE,
        PermissionEnum.DEVICE_TYPES_MODULE_VIEW,
        PermissionEnum.DEVICE_TYPES_MODULE_DELETE,
        PermissionEnum.DEVICE_TYPES_MODULE_UPDATE,

        PermissionEnum.USER_MODULE_CREATE,
        PermissionEnum.USER_MODULE_VIEW,
        PermissionEnum.USER_MODULE_DELETE,
        PermissionEnum.USER_MODULE_UPDATE,
      ],
      TYPE: 'OR',
    },
    {
      FE_KEY: PermissionEnum.SERVICES_MODULE,
      BE_KEY: [
        PermissionEnum.SERVICES_MODULE_CREATE,
        PermissionEnum.SERVICES_MODULE_VIEW,
        PermissionEnum.SERVICES_MODULE_DELETE,
        PermissionEnum.SERVICES_MODULE_UPDATE,
      ],
      TYPE: 'OR',
    },
    {
      FE_KEY: PermissionEnum.GIVE_TICKET,
      BE_KEY: [
        PermissionEnum.TICKET_MODULE_CREATE,
        PermissionEnum.SERVICE_DESK_MODULE_CONTROL_TICKET,
      ],
      TYPE: 'OR',
    },
    {
      FE_KEY: PermissionEnum.DEVICES_MODULE,
      BE_KEY: [
        PermissionEnum.DEVICES_MODULE_CREATE,
        PermissionEnum.DEVICES_MODULE_DELETE,
        PermissionEnum.DEVICES_MODULE_UPDATE,
        PermissionEnum.DEVICES_MODULE_VIEW,
      ],
      TYPE: 'OR',
    },
    {
      FE_KEY: PermissionEnum.SERVICE_DESK_MODULE,
      BE_KEY: [
        PermissionEnum.SERVICE_DESK_MODULE_CREATE,
        PermissionEnum.SERVICE_DESK_MODULE_DELETE,
        PermissionEnum.SERVICE_DESK_MODULE_UPDATE,
        PermissionEnum.SERVICE_DESK_MODULE_VIEW,
      ],
      TYPE: 'OR',
    },
    {
      FE_KEY: PermissionEnum.DEVICE_TYPES_MODULE,
      BE_KEY: [
        PermissionEnum.DEVICE_TYPES_MODULE_VIEW,
        PermissionEnum.DEVICE_TYPES_MODULE_DELETE,
        PermissionEnum.DEVICE_TYPES_MODULE_CREATE,
        PermissionEnum.DEVICE_TYPES_MODULE_UPDATE,
      ],
      TYPE: 'OR',
    },
    {
      FE_KEY: PermissionEnum.USER_MODULE,
      BE_KEY: [
        PermissionEnum.USER_MODULE_CREATE,
        PermissionEnum.USER_MODULE_DELETE,
        PermissionEnum.USER_MODULE_UPDATE,
        PermissionEnum.USER_MODULE_VIEW,
      ],
      TYPE: 'OR',
    },
  ],
};
