export default {
  'evaluate.name': 'Evaluate',
  'evaluate.title': 'Customer assessment management',
  'evaluate.information': 'Evaluate Information',

  'evaluate.ticketIndex': 'Stt evaluated',
  'evaluate.service.serviceName': 'Usage service name',
  'evaluate.serviceDesk.serviceDeskName': 'Service counter',
  'evaluate.ticketRating': 'Evaluate',
  'evaluate.internalComment': 'Content',
  'evaluate.ratingDateTime': 'Evaluation time',
  'evaluate.action': 'Action',

  'evaluate.serviceName': 'Usage service',
  'evaluate.serviceDeskName': 'Service counter',
  'evaluate.processBy': 'Performer',
  'evaluate.createDateTime': 'Execution time',

  'evaluate.createdAt': 'Choose time',
  'evaluate.table.service': 'Service',
  'evaluate.table.serviceDesk': 'Service counter',

  'evaluate.filter.ticketRating': 'Evaluate',
  'evaluate.ticketRating.one': '1 star',
  'evaluate.ticketRating.two': '2 star',
  'evaluate.ticketRating.three': '3 star',
  'evaluate.ticketRating.four': '4 star',
  'evaluate.ticketRating.five': '5 star',
};
