export default {
  'roles.title': 'Quản lý vai trò',
  'roles.roleName': 'Tên vai trò',
  'roles.createDateTime': 'Ngày tạo',
  'roles.internalComment': 'Mô tả',
  'roles.list': 'Danh sách vai trò',
  'roles.action': 'Hành động',
  'roles.deleteContent': 'Bạn có muốn xóa vai trò đã chọn?',
  'roles.permissions': 'Phân quyền chức năng',
  'roles.create': 'Tạo vai trò',
  'roles.update': 'Chỉnh sửa vai trò',
  'roles.validatorRoleName': 'Tên vai trò không hợp lệ',
  'roles.information': 'Thông tin vai trò',

  roles: 'Vai trò',
  RolesModule: 'Vai trò',
  'RolesModule.create': 'Tạo vai trò',
  'RolesModule.delete': 'Xóa vai trò',
  'RolesModule.update': 'Chỉnh sửa vai trò',
  'RolesModule.view': 'Xem chi tiết vai trò',

  users: 'Người dùng',
  UsersModule: 'Người dùng',
  'UsersModule.create': 'Tạo người dùng',
  'UsersModule.delete': 'Xóa người dùng',
  'UsersModule.update': 'Chỉnh sửa người dùng',
  'UsersModule.view': 'Xem chi tiết người dùng',

  services: 'Dịch vụ',
  ServicesModule: 'Dịch vụ',
  'ServicesModule.create': 'Tạo dịch vụ',
  'ServicesModule.delete': 'Xóa dịch vụ',
  'ServicesModule.update': 'Chỉnh sửa dịch vụ',
  'ServicesModule.view': 'Xem chi tiết dịch vụ',

  devices: 'Thiết bị',
  DevicesModule: 'Thiết bị',
  'DevicesModule.create': 'Tạo thiết bị',
  'DevicesModule.delete': 'Xóa thiết bị',
  'DevicesModule.update': 'Chỉnh sửa thiết bị',
  'DevicesModule.view': 'Xem chi tiết thiết bị',

  deviceTypes: 'Loại thiết bị',
  DeviceTypesModule: 'Loại thiết bị',
  'DeviceTypesModule.create': 'Tạo loại thiết bị',
  'DeviceTypesModule.delete': 'Xóa loại thiết bị',
  'DeviceTypesModule.update': 'Chỉnh sửa loại thiết bị',
  'DeviceTypesModule.view': 'Xem chi tiết loại thiết bị',

  ticket: 'Cấp số',
  TicketModule: 'Cấp số',
  'TicketModule.create': 'Tạo cấp số',
  'TicketModule.delete': 'Xóa cấp số',
  'TicketModule.update': 'Chỉnh sửa cấp số',
  'TicketModule.view': 'Xem chi tiết cấp số',

  serviceDesk: 'Quầy dịch vụ',
  ServiceDeskModule: 'Quầy dịch vụ',
  'ServiceDeskModule.create': 'Tạo quầy dịch vụ',
  'ServiceDeskModule.delete': 'Xóa quầy dịch vụ',
  'ServiceDeskModule.update': 'Chỉnh sửa quầy dịch vụ',
  'ServiceDeskModule.view': 'Xem chi tiết quầy dịch vụ',
  'ServiceDeskModule.controlTicket': 'Quản lý cấp số',

  Rating: 'Đánh giá',
  'Rating.view': 'Xem đánh giá',
  Report: 'Báo cáo',
  'Report.view': 'Xem báo cáo',
};
