import React from 'react';

import { LaptopOutlined } from '@ant-design/icons';
import { IRouter } from '@routers/interface';
import { PermissionEnum } from '@shared/permissionCode/code';

const routerDevice: IRouter = {
  path: '/device',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'device.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <LaptopOutlined />,
  },
  permissionCode: PermissionEnum.DEVICES_MODULE,
};

export default routerDevice;
