import React from 'react';
import { Bookmark } from 'react-feather';

import { IRouter } from '@routers/interface';
import { PermissionEnum } from '@shared/permissionCode/code';

const routerRoles: IRouter = {
  path: '/setting/roles',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'roles.title', //translate here for breadcrumb and sidebar
  masterLayout: true,

  icon: <Bookmark />,
  permissionCode: PermissionEnum.ROLES_MODULE,
};

export default routerRoles;
