import dayjs from 'dayjs';

import RoleEntity from '@modules/roles/entity';

class UserEntity {
  userName = '';

  userPhone: string = '';

  userPassword: string = '';

  userFullName: string = '';

  userAvatar: string = '';

  userEmail: string = '';

  roleId: string = '';

  role?: RoleEntity;

  createdBy?: string = '';

  internalComment?: string = '';

  isActive?: boolean = false;

  createDateTime?: any;

  id: string = '';

  createdAt: string = '';

  constructor(user?: Partial<UserEntity>) {
    if (!user) {
      return;
    }
    Object.assign(this, user);
    this.createDateTime = user?.createDateTime ? dayjs(user?.createDateTime) : '';
    this.createdAt = user?.createdAt ? dayjs(user?.createdAt).format('DD/MM/YYYY') : '';
  }

  static createArrayUser(arrUser: Array<Partial<UserEntity>>): Array<UserEntity> {
    if (!arrUser) {
      return [];
    }
    return arrUser.map(x => new UserEntity(x));
  }
}

export default UserEntity;
