import React from 'react';

import { BankOutlined } from '@ant-design/icons';
import { IRouter } from '@routers/interface';
import { PermissionEnum } from '@shared/permissionCode/code';

const routerServiceDeskCms: IRouter = {
  path: '/service-desk-cms',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'serviceDeskCms.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <BankOutlined />,
  },
  permissionCode: PermissionEnum.SERVICE_DESK_MODULE,
};

export default routerServiceDeskCms;
