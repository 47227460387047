export default {
  'reset.password.title': 'Đặt lại mật khẩu',
  'reset.password.newPassword': 'Mật khẩu mới',
  'reset.password.confirm.newPassword': 'Xác nhận mật khẩu mới',
  'reset.password.title.error': 'Lỗi kết nối !!',
  'reset.password.notification':
    'Đã có lỗi trong quá trình kết nối hoặc link của bạn đã hết hạn. Vui lòng thử lại hoặc yêu cầu gửi lại link để đặt lại mật khẩu của bạn.',
  'reset.password.not.match': 'Hai mật khẩu bạn đã nhập không khớp!',
  'checkCode.label': 'Bạn vui lòng nhập mã code được nhận qua email vào đây.',
  'checkCode.title': 'Kiểm tra mã code',
  'checkCode.notMatch': 'Mã code không đúng định dạng',
  'reset.format.password': 'Mật khẩu không đúng định dạng',
  'reset.password': 'Mật khẩu',
  'reset.confirmPassword': 'Nhập lại mật khẩu',
};
